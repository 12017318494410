import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { MainButton } from "../components/Button";
import { Input } from "../components/InputFields";
import useYupValidation from "../utils/useYupValidation.hook";
import * as yup from "yup";

const Ct = styled.div`
  position: relative;
  padding: 0 30px;

  padding-top: 6.5rem;

  @media screen and (min-width: 768px) {
    padding-top: 10rem;
  }

  h1 {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    h1 {
      color: #fff;
    }
  }
  .color-bg {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 100%;
      height: 40%;
      background: ${({ theme }) => theme.colors.primary};
      position: fixed;
      top: 0;
      left: 0;
      z-index: -999;
    }
  }
  .content {
    display: flex;
    z-index: 30;
    position: relative;
    flex-direction: column;
    margin-top: 2rem;
    justify-content: center;
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.grey_background};

    @media screen and (min-width: 768px) {
      box-shadow: 0px 8px 33px 1px rgba(0, 5, 40, 0.05);
      background: ${({ theme }) => theme.colors.white};

      border-radius: 17px;
      width: 80%;
      /* padding: 50px; */
      margin: 52px auto;
    }

    .row {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
      .col {
        @media screen and (min-width: 768px) {
          padding: 50px;
        }
        .title {
          font-weight: 400;
          font-size: 16px;

          @media screen and (min-width: 768px) {
            font-weight: 400;
            font-size: 20px;

            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }

      .send-req {
        @media screen and (min-width: 1024px) {
          width: 60%;
        }
      }

      .contact-info {
        margin-top: 20px;
        @media screen and (min-width: 1024px) {
          margin-top: 0;
        }
        .info {
          margin: 10px 0;
          p {
            margin: 10px 0;
            padding: 10px 0;
            border-bottom: 1px solid
              ${({ theme }) => theme.colors.grey_background};
            span {
              display: block;
              margin: 10px 0;
            }
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }
        }
        @media screen and (min-width: 1024px) {
          width: 40%;
          background: ${({ theme }) => theme.colors.grey_background};
          border-radius: 17px;
        }
      }
    }

    h3 {
      font-weight: 400;
      font-size: 16px;

      margin: 28px 0;

      @media screen and (min-width: 768px) {
        font-weight: 400;
        font-size: 20px;

        color: ${({ theme }) => theme.colors.primary};
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 27px;
    }
  }
`;

function Contact() {
  const [values, setValues] = useState({
    fullName: "",
    userEmail: "",
    message: "",
    companyEmail: "contactinfo@bosscab.com",
  });

  const formSchema = yup.object().shape({
    fullName: yup.string().required("Name is required"),
    userEmail: yup.string().email().required(),
    message: yup.string().required("Message is required"),
  });

  const { onSubmit, onFieldChange, errors, reValidate } = useYupValidation(
    formSchema,
    setValues,
    values
  );

  useEffect(() => {
    reValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = () => {
    let config = {
      method: "post",
      url: "https://bosscabapis.nugitech.com/contact-us",
      headers: {},
      data: values,
    };

    axios(config)
      .then(response => {
        console.log(JSON.stringify(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Ct>
      <div className="color-bg"></div>
      <h1>GET IN TOUCH</h1>

      <div className="content">
        <div className="row">
          <form
            className="col send-req"
            onSubmit={e => onSubmit(e, handleSubmit)}
          >
            <h2 className="title">Send your request</h2>
            <Input
              label="Full name"
              type="text"
              name="fullName"
              onChange={onFieldChange}
              value={values.fullName}
              error={errors.fullName}
              marginY="12px"
            />
            <Input
              label="Email"
              type="text"
              name="userEmail"
              onChange={onFieldChange}
              value={values.userEmail}
              error={errors.userEmail}
              marginY="12px"
            />

            <Input
              label="Message"
              type="text"
              name="message"
              className="message"
              onChange={onFieldChange}
              value={values.message}
              error={errors.message}
              marginY="12px"
            />
            <MainButton justify="flex-start" text="Submit" />
          </form>
          <div className="col contact-info">
            <h2 className="title">Contact Information</h2>
            <div className="info">
              <p>
                <span>Phone :</span> (+234) 9012345678
              </p>
              <p>
                <span>Email :</span> {values.companyEmail}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Ct>
  );
}

export default Contact;
