import React from "react";
import styled from "styled-components";

import Standing from "../assets/standing.svg";
import Mobile from "../assets/mobile.svg";
import Group from "../assets/group.svg";

const Wbc = styled.div`
  position: relative;
  padding: 0 30px;

  padding-top: 6.5rem;

  @media screen and (min-width: 768px) {
    padding-top: 10rem;
  }

  h1 {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    h1 {
      color: #fff;
    }
  }
  .color-bg {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 100%;
      height: 40%;
      background: ${({ theme }) => theme.colors.primary};
      position: fixed;
      top: 0;
      left: 0;
      z-index: -999;
    }
  }
  .content {
    display: flex;
    z-index: 30;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.grey_background};

    @media screen and (min-width: 768px) {
      box-shadow: 0px 8px 33px 1px rgba(0, 5, 40, 0.05);
      background: ${({ theme }) => theme.colors.white};

      border-radius: 17px;
      width: 80%;
      padding: 50px;
      margin: 52px auto;
    }

    .row {
      @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
      }

      .col {
        svg {
          margin-bottom: 20px;
        }
        margin: 30px 0;
        h4 {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    h3 {
      font-weight: 400;
      font-size: 16px;

      margin: 28px 0;

      @media screen and (min-width: 768px) {
        font-weight: 400;
        font-size: 20px;

        color: ${({ theme }) => theme.colors.primary};
        /* identical to box height */
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 27px;
    }
  }
`;

function WhyBosscab() {
  return (
    <Wbc>
      <div className="color-bg"></div>
      <h1>Why Bosscab?</h1>

      <div className="content">
        <h3>WHY US ?</h3>
        <div className="row">
          <div className="col">
            <img src={Standing} alt="standing" />
            <h4>Be your own boss</h4>
            <p>
              Ride conveniently and in style, whether you're a driver or a
              passenger, it really doesn't matter, you're in control.
            </p>
          </div>
          <div className="col">
            <img src={Mobile} alt="mobile" />

            <h4>Innovative pay</h4>
            <p>
              Pay for your rides by just the push of a button, you don't have to
              worry about limitations associated with traditional payment
              systems. Instant settlement for drivers.
            </p>
          </div>
          <div className="col">
            <img src={Group} alt="group" />
            <h4>Ride together</h4>
            <p>
              You don't have to ride alone, make use of our group ride packages
              with your family, friends or work buddies at pocket friendly rates
              and arrive your destination together, just in time for that
              meeting, lunch or date.
            </p>
          </div>
        </div>
      </div>
    </Wbc>
  );
}

export default WhyBosscab;
