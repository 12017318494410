import React from "react";
import styled from "styled-components";

const SecBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin: ${({ marginY }) => marginY} 0;
  width: 100%;
  button {
    background: ${({ theme, inverse }) =>
      inverse ? theme.colors.primary : theme.colors.white};
    border: 1px solid
      ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.primary};
    color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.primary};
    border-radius: 50px;
    /* width: 309px; */
    /* height: 54px; */
    min-width: 8.5625rem;
    padding: 12px 34px;
    height: 2.625rem;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.primary};
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.black : theme.colors.white};
    }
  }
`;

function SecondaryButton({
  inverse = false,
  onClick,
  loading = false,
  disabled = false,
  text = "Insert Text",
  justify = "center",
  marginY = "30px",
}) {
  return (
    <SecBtn inverse={inverse} marginY={marginY} justify={justify}>
      <button onClick={onClick} disabled={disabled}>
        {text}
      </button>
    </SecBtn>
  );
}

export default SecondaryButton;
