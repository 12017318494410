import React from "react";
import styled from "styled-components";
import { ReactComponent as Right } from "../assets/right.svg";
import { ReactComponent as AboutPeople } from "../assets/about-people.svg";
import { Link } from "react-router-dom";

const Abt = styled.div`
  position: relative;
  padding: 0 30px;

  padding-top: 6.5rem;

  @media screen and (min-width: 768px) {
    padding-top: 10rem;
  }

  h1 {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    h1 {
      color: #fff;
    }
  }
  .color-bg {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 100%;
      height: 40%;
      background: ${({ theme }) => theme.colors.primary};
      position: fixed;
      top: 0;
      left: 0;
      z-index: -999;
    }
  }
  .content {
    display: flex;
    z-index: 30;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.grey_background};

    .first-p {
      /* margin-bottom: 30px; */
    }

    & > svg {
      display: none;
    }

    @media screen and (min-width: 768px) {
      box-shadow: 0px 8px 33px 1px rgba(0, 5, 40, 0.05);
      background: ${({ theme }) => theme.colors.white};

      border-radius: 17px;
      width: 80%;
      padding: 50px;
      margin: 52px auto;

      .first-p {
        width: 70%;
      }
    }

    @media screen and (min-width: 1024px) {
      & > svg {
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 16px;

      margin: 28px 0;

      @media screen and (min-width: 768px) {
        font-weight: 400;
        font-size: 20px;

        color: ${({ theme }) => theme.colors.primary};
        /* identical to box height */
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 27px;
    }
  }
`;

const Button = styled(Link)`
  margin: 60px 0 10px;
  width: 100%;
  max-width: 319px;
  height: 58px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 33px;
  padding-right: 8px;

  span {
    background: ${({ theme }) => theme.colors.white};
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`;

function About() {
  return (
    <Abt>
      <div className="color-bg"></div>
      <h1>About Bosscab</h1>

      <div className="content">
        <h3>WHO WE ARE</h3>
        <AboutPeople />
        <p className="first-p">
          We're creating opportunities while making the world a simpler and
          easier place to live in. Because we are passionate about togetherness
          and human interactions, we have built a solution for you to move
          around without necessarily being apart from those you hold dear. And
          because we believe in dreams, and that when dreams collide, great
          things are achieved, we have provided a service to ensure that your
          dreams go where they want or need to be and that they arrive at their
          destinations. We are a multi-cultural, multi-ethnic, and
          multi-religious workforce. Integrity and diversity are at the very
          core of our goals and objectives. In putting together our piece of
          technology to serve you, we have prioritized your safety, whether you
          are in the passenger's seat or behind the wheels.
        </p>
        {/* <p>
          Nullam in eleifend justo, ac sagittis ipsum.Fusce consequat nisi
          fringilla finibus sollicitud.Etiam eleifend neque eget dolor commodo
          commodo. Etiam vel accumsan massa. Nam a lectus sit amet diam
          facilisis egestas. Nam volutpat pharetra gravida.
        </p> */}
        <Button to="/contact">
          Contact us
          <span>
            <Right />
          </span>
        </Button>
      </div>
    </Abt>
  );
}

export default About;
