import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Layout from "./components/Layout";
import ThemeContextProvider from "./context/Theme.context";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import WhyBosscab from "./pages/WhyBosscab";

const Theme = {
  colors: {
    primary: "#101D70",
    white: "#fff",
    grey_background: "#F7F7F7",
  },
};

function App() {
  React.useEffect(() => {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "pdfewggu",
    });
    window.Intercom("update");
  });

  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <ThemeContextProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/why-bosscab" element={<WhyBosscab />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
          </Routes>
        </ThemeContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
