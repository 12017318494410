import { Fragment } from "react";
import { memo, useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as Error } from "../../assets/error.svg";

const Floating = styled.div`
  .floating-group {
    position: relative;
    max-width: 633px;
    margin-top: 4px;
    display: flex;
    align-items: flex-end;
    height: 56px;
    /* background: green; */

    width: 100%;

    /* overflow-x: hidden; */

    input {
      background: transparent;
      font-size: 16px;
      padding: 10px 10px 8px 5px;
      display: block;
      width: 100%;
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.black};
      border: none;
      border-radius: 0;
      border-bottom: 1px solid
        ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      /* margin: 10px 0; */

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }

      &:focus {
        outline: none;
        background: transparent;
      }

      &:focus ~ label,
      &:not(:empty) ~ label {
        top: 0px;
        opacity: 0.7;

        font-size: 13px;
        color: ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      }
    }

    /* input:-internal-autofill-selected {
      appearance: none !important;
      background-image: none !important;
      background-color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.black} !important;
      color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.black} !important;
    } */

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    label {
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.black};
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 24px;
      transition: 300ms ease all;

      &.shrink {
        top: 0px;
        opacity: 0.7;
        font-size: 13px;
        color: ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      }
    }
  }

  & > span {
    svg {
      height: 16px;
      width: 16px;
    }
    margin-top: 4px;
    height: 16px;
    /* background: yellow; */
    visibility: hidden;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    align-items: center;
    gap: 4px;
    visibility: hidden;

    &.error {
      visibility: visible;
    }
  }
`;

const Input = memo(
  ({
    type = "text",
    label = "Default LAbel",
    value,
    name,
    error,
    pattern,
    onChange,
    autoComplete,
    required,
    inverse,
  }) => {
    const handleChange = useCallback(
      e => {
        let { value } = e.target;
        onChange(name, value);
      },
      [onChange, name]
    );

    return (
      <Floating inverse={inverse}>
        <div className="floating-group">
          <input
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            autoComplete={autoComplete}
            required={required}
          />
          <label className={value.length > 0 ? "shrink" : undefined}>
            {label}
          </label>
        </div>
        <span className={!!error ? "error" : undefined}>
          {error &&
            error?.map((err, i) => (
              <Fragment key={i}>
                <Error /> {err}
              </Fragment>
            ))}
        </span>
      </Floating>
    );
  }
);

export default Input;
