import LottieAnimation from "./LottieAnimation";
import * as animationData from "../scroll.json";
import styled from "styled-components";

const Scroll = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
`;

export default function ScrollButton() {
  return (
    <Scroll>
      <LottieAnimation
        animationData={animationData.default}
        width={100}
        height={100}
      />
      {/* <Text variant="caption" color="light">
          Scroll Up
        </Text> */}
    </Scroll>
  );
}
