import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/Theme.context";
import { ReactComponent as Close } from "../assets/close.svg";
import { ReactComponent as Home } from "../assets/home-icon.svg";
import { ReactComponent as Why } from "../assets/why-icon.svg";
import { ReactComponent as About } from "../assets/about-icon.svg";
import { ReactComponent as Phone } from "../assets/phone-icon.svg";

const SidebarStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  padding-top: 5.4375rem;
  padding-left: 1.875rem;
  transition: all 0.5s ease;
  background: ${({ theme, alt }) =>
    alt ? theme.colors.primary : theme.colors.grey_background};

  @media screen and (min-width: 1024px) {
    display: none;
  }

  svg {
    stroke: ${({ theme, alt }) =>
      alt ? theme.colors.grey_background : theme.colors.primary} !important;

    fill: ${({ theme, alt }) =>
      alt ? theme.colors.grey_background : theme.colors.primary} !important;
  }

  .close-btn {
    svg {
      stroke: ${({ theme, alt }) =>
        alt ? theme.colors.grey_background : theme.colors.primary};
      margin-right: 17px;
    }
    color: ${({ theme, alt }) =>
      alt ? theme.colors.grey_background : theme.colors.primary};
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  .register-btns {
    margin-bottom: 48px;
    margin-top: 59px;
    display: flex;
    .register-btn {
      text-decoration: none;

      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      /* padding: 9px 20px; */
      width: 93px;
      height: 37px;
      border-radius: 50px;

      &:visited {
        color: ${({ theme, alt }) =>
          alt ? theme.colors.primary : theme.colors.grey_background};
      }
    }
    .sign-up {
      background-color: ${({ theme, alt }) =>
        alt ? theme.colors.grey_background : theme.colors.primary};
      color: ${({ theme, alt }) =>
        alt ? theme.colors.primary : theme.colors.grey_background};
      margin-right: 24px;
      &:visited {
        color: ${({ theme, alt }) =>
          alt ? theme.colors.primary : theme.colors.grey_background};
      }
    }
    .sign-in {
      background-color: ${({ theme, alt }) =>
        alt ? theme.colors.primary : theme.colors.grey_background};
      color: ${({ theme, alt }) =>
        alt ? theme.colors.grey_background : theme.colors.primary};
      border: 1px solid
        ${({ theme, alt }) =>
          alt ? theme.colors.grey_background : theme.colors.primary};

      &:visited {
        color: ${({ theme, alt }) =>
          alt ? theme.colors.grey_background : theme.colors.primary};
      }
    }
  }

  .nav-links {
    min-height: 30%;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .nav-link {
      svg {
        margin-right: 30px;
      }

      &:visited {
        color: ${({ theme, alt }) =>
          alt ? theme.colors.grey_background : theme.colors.primary};
      }

      text-decoration: none;
      color: ${({ theme, alt }) =>
        alt ? theme.colors.grey_background : theme.colors.primary};
      font-weight: 400;
      font-size: 16px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

function Sidebar() {
  const { changeShowSideNav, altTheme, setAltTheme, setShowSideNav } =
    React.useContext(ThemeContext);

  return (
    <SidebarStyles alt={altTheme}>
      <div className="close-btn" onClick={changeShowSideNav}>
        <Close />
        <span>Close menu</span>
      </div>
      <div className="register-btns">
        <a
          className="register-btn sign-up"
          href="https://bosscab-driver.nugitech.com"
        >
          Sign up
        </a>
        <a
          className="register-btn sign-in"
          href="https://bosscab-driver.nugitech.com/login"
        >
          Sign in
        </a>
      </div>
      <div className="nav-links">
        <Link
          className="nav-link"
          onClick={() => {
            setAltTheme(false);
            setShowSideNav(false);
          }}
          to="/"
        >
          <Home />
          <span>Home</span>
        </Link>
        <Link
          className="nav-link"
          onClick={() => {
            setAltTheme(true);
            setShowSideNav(false);
          }}
          to="/why-bosscab"
        >
          <Why />
          <span>Why Bosscab </span>
        </Link>
        <Link
          className="nav-link"
          onClick={() => {
            setAltTheme(true);
            setShowSideNav(false);
          }}
          to="/about"
        >
          <About />
          <span>About Us</span>
        </Link>
        <Link
          className="nav-link"
          onClick={() => {
            setAltTheme(true);
            setShowSideNav(false);
          }}
          to="/contact"
        >
          <Phone />
          <span>Contact Us</span>
        </Link>
      </div>
    </SidebarStyles>
  );
}

export default Sidebar;
