import React from "react";
import {
  RadioGroup,
  Radio as RadioElement,
  useRadioGroup,
} from "react-radio-input";
import styled from "styled-components";

export const RadioGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 8px;
`;

function Radio({ value = "", label = "" }) {
  const { selectedValue } = useRadioGroup();
  const isSelected = value === selectedValue;
  return (
    <Label isSelected={isSelected}>
      <RadioElement value={value} />
      <span>{label}</span>
    </Label>
  );
}

export default Radio;
