import { useState, createContext } from "react";

export const ThemeContext = createContext({});

const ThemeContextProvider = props => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [altTheme, setAltTheme] = useState(false);

  function changeShowSideNav() {
    setShowSideNav(!showSideNav);
  }

  return (
    <ThemeContext.Provider
      value={{
        changeShowSideNav,
        showSideNav,
        setShowSideNav,
        altTheme,
        setAltTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
