import React from "react";
import styled from "styled-components";
import { ReactComponent as Toggler } from "../assets/toggler.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ThemeContext } from "../context/Theme.context";
import { NavLink } from "react-router-dom";

const Nv = styled.nav`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 30px 10px;
  z-index: 10;

  @media screen and (min-width: 768px) {
    padding: 30px 60px 10px;
  }
  .logo {
    width: 30px;
    fill: ${({ theme, alt }) =>
      alt ? theme.colors.primary : theme.colors.white};
    @media screen and (min-width: 1024px) {
      width: 50.07px;
    }
  }

  .toggler {
    fill: ${({ theme, alt }) =>
      alt ? theme.colors.primary : theme.colors.white};
    /* cursor: pointer; */
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .nav-links {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      max-width: 620px;
    }

    .nav-link {
      font-weight: 400;
      font-size: 16px;
      display: inline-block;
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      display: inline-block;
      opacity: 0.8;

      &.active {
        font-weight: 500;
        opacity: 1;
      }
    }

    .reg-btn {
      font-weight: 400;
      font-size: 16px;

      width: 111px;
      height: 43px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &.sign-up {
        background-color: ${({ theme }) => theme.colors.white};
        &:visited {
          /* color: ${({ theme }) => theme.colors.white}; */
        }
      }
      &.sign-in {
        border: 1px solid ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
        &:visited {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

function Navbar() {
  const { changeShowSideNav, altTheme } = React.useContext(ThemeContext);
  let active = "active";

  return (
    <Nv alt={altTheme}>
      <NavLink to="/">
        <Logo className="logo" />
      </NavLink>
      <Toggler className="toggler" onClick={changeShowSideNav} />
      <ul className="nav-links">
        <NavLink
          to="/why-bosscab"
          className={({ isActive }) =>
            isActive ? active + " nav-link" : "nav-link"
          }
        >
          Why Bosscab
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? active + " nav-link" : "nav-link"
          }
        >
          About Us
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive ? active + " nav-link" : "nav-link"
          }
        >
          Contact Us
        </NavLink>
        <a
          href="https://bosscab-driver.nugitech.com"
          className="reg-btn sign-up"
        >
          Sign up
        </a>
        <a
          href="https://bosscab-driver.nugitech.com/login"
          className="reg-btn sign-in"
        >
          Sign in
        </a>
      </ul>
    </Nv>
  );
}

export default Navbar;
