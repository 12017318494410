import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { ThemeContext } from "../context/Theme.context";

import styled from "styled-components";
import gsap from "gsap";

const Lo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;

  & > main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.grey_background};
  }
`;

function Layout() {
  const { showSideNav } = React.useContext(ThemeContext);

  const option = useMemo(
    () =>
      showSideNav
        ? {
            duration: 0.4,
            overflow: "hidden",
            ease: "power4.out",
            scale: 0.9,
            borderRadius: "35px",
            x: "75%",
            top: "50%",
            translateY: "-50%",
          }
        : {
            duration: 0.4,
            overflowY: "auto",
            ease: "power2.in",
            scale: 1,
            borderRadius: 0,
            x: 0,
            top: 0,
            translateY: 0,

            height: "100%",
          },
    [showSideNav]
  );

  useEffect(() => {
    gsap.to(".ck", option);
  }, [option]);
  return (
    <Lo>
      <Sidebar />
      <main className="ck">
        <Navbar />

        <Outlet />
      </main>
    </Lo>
  );
}

export default Layout;
