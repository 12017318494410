import React from "react";
import styled from "styled-components";
import ScrollButton from "../components/ScrollButton";
import HeroPng from "../assets/hero.webp";

const Hero = styled.div`
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${HeroPng});
  background-repeat: no-repeat;
  background-size: auto 75%;
  background-position: center bottom;
  padding: 0 30px;
  padding-top: 6.5rem;

  @media screen and (min-width: 768px) {
    padding-top: 10rem;
  }

  .ctrl {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px) {
      align-items: center;
      justify-content: flex-start;
    }
  }

  .title {
    font-weight: 700;
    font-size: 44px;

    color: ${({ theme }) => theme.colors.white};

    @media screen and (min-width: 768px) {
      div {
        display: inline;
      }
    }

    @media screen and (min-width: 1024px) {
      font-size: 64px;

      div {
        display: inline;
      }
    }
  }
  .apply-btn {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;

    width: 154px;
    height: 43.15px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50px;
    text-decoration: none;
    color: #000;

    &:visited {
      color: #000;
    }
  }
`;

const Home = () => {
  return (
    <Hero>
      <div className="ctrl">
        <h1 className="title">
          Hop in, <div>Let's Drive</div>
        </h1>
        <a className="apply-btn" href="https://bosscab-driver.nugitech.com">
          Apply to Drive
        </a>
      </div>
      <ScrollButton />
    </Hero>
  );
};

export default Home;
